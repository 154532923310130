import { GetStaticProps } from 'next'
import React from 'react'
import { useIntl } from 'react-intl'

import { ElleAdvantageSlice } from '@/components/_shared/_slice/ElleAdvantageSlice'
import { PromoBannerSlice } from '@/components/_shared/_slice/PromoBannerSlice'
import { PillarListCarouselSlice } from '@/components/discipline/_slice/PillarListCarouselSlice'
import { HomeHeroSlice } from '@/components/home/_slice/HomeHeroSlice'
import { HomeOlybeElleStatementSlice } from '@/components/home/_slice/HomeOlybeElleStatementSlice'
import { HomeWellBeingSlice } from '@/components/home/_slice/HomeWellBeingSlice'
import { LessonAreaListSelectorSlice } from '@/components/lesson/_slice/LessonAreaListSelectorSlice'
import { LessonBookingContextSlice } from '@/components/lesson/_slice/LessonBookingContextSlice'
import { LessonListDualArea } from '@/components/lesson/lesson/LessonListDualArea'
import { SurveyFeedbackListCarouselSlice } from '@/components/survey/_slice/SurveyFeedbackListCarouselSlice'
import { TeacherListCarouselSlice } from '@/components/teacher/_slice/TeacherListCarouselSlice'
import { VideoListSlice } from '@/components/video/_slice/VideoListSlice'
import { SWR_KEY__LESSON_DUALAREA_RESULTS } from '@/hooks/swr/useSwr-lessonDualAreaResults'
import {
  PROPERTY_KEY_SLUGS_1,
  SWR_KEY__PROPERTY_RESULTS,
} from '@/hooks/swr/useSwr-propertyResults'
import { SWR_KEY__VIDEO_RESULTS } from '@/hooks/swr/useSwr-videoResults'
import { INextPageWithLayout } from '@/pages/_app'
import { getLessonDualAreaResults } from '@/services/api-graphql-lesson'
import {
  getPropertyResults,
  IPropertyResultsVariables,
} from '@/services/api-graphql-property'
import { getVideoResults } from '@/services/api-graphql-video'
import { HOME_LESSONS_FEEDBACKS } from '@/utils/feedbacks'
import { serializeSwrApiKey } from '@/utils/swr'

const HomePage: INextPageWithLayout = () => {
  const intl = useIntl()

  return (
    <>
      <HomeHeroSlice />
      <HomeOlybeElleStatementSlice />
      <PromoBannerSlice />

      <ElleAdvantageSlice />

      <PillarListCarouselSlice />
      <LessonBookingContextSlice
        title={intl.formatMessage({
          defaultMessage: 'Trouvez votre prochain cours',
          description: 'HomePage > LessonBookingContextSlice: title',
        })}
        sectionTitle={intl.formatMessage({
          defaultMessage: 'Nos cours',
          description: 'HomePage > LessonBookingContextSlice: section title',
        })}
      >
        <LessonListDualArea />
      </LessonBookingContextSlice>
      <VideoListSlice />
      <SurveyFeedbackListCarouselSlice feedbacks={HOME_LESSONS_FEEDBACKS} />
      <HomeWellBeingSlice />
      <TeacherListCarouselSlice gqlQuery="Random" />
      <PromoBannerSlice slot={2} />
      <LessonAreaListSelectorSlice />
    </>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const gqlVariablesPropertyList = {
    slugs: PROPERTY_KEY_SLUGS_1,
  } as IPropertyResultsVariables
  const initialPropertyList = await getPropertyResults(gqlVariablesPropertyList)

  const initialLessonListDualArea = await getLessonDualAreaResults()
  const initialVideoList = await getVideoResults()

  return {
    props: {
      swrFallback: {
        [serializeSwrApiKey(SWR_KEY__PROPERTY_RESULTS, gqlVariablesPropertyList)]:
          initialPropertyList,
        [serializeSwrApiKey(SWR_KEY__LESSON_DUALAREA_RESULTS)]: initialLessonListDualArea,
        [serializeSwrApiKey(SWR_KEY__VIDEO_RESULTS)]: initialVideoList,
      },
    },
    revalidate: 1800, // 30mn
  }
}

// eslint-disable-next-line import/no-default-export
export default HomePage
