import { KeyedMutator, SWRConfiguration } from 'swr'

import {
  getVideoResults,
  IVideoDecoratedGQLResults,
  IVideoGQLResults,
  IVideoResultsVariables,
} from '@/services/api-graphql-video'
import { HTTPError } from '@/utils/api-helpers'
import { decorateVideo } from '@/utils/decorator'
import { buildGenericSwrMutate } from '@/utils/swr'

import { useSWRAuth } from './useSwr-auth'
import { useSWRCustom } from './useSwr-custom'

export const SWR_KEY__VIDEO_RESULTS = 'video_results'

interface IReturnType {
  videoListLoading: boolean
  videoList: IVideoDecoratedGQLResults
  videoListError: HTTPError
  videoListValidating: boolean
  mutateVideoList: KeyedMutator<IVideoGQLResults>
}

export const useSWRVideoResults = (
  gqlVariables: IVideoResultsVariables = {},
  swrConfig: SWRConfiguration = { revalidateOnFocus: false }
): IReturnType => {
  const { authenticated, payload } = useSWRAuth()
  const swrAbort = ['Recommended'].includes(gqlVariables.gqlQuery) && !authenticated
  const { data, error, mutate, isLoading, isValidating } = useSWRCustom<IVideoGQLResults>(
    [!swrAbort && SWR_KEY__VIDEO_RESULTS, payload?.uuid],
    getVideoResults,
    gqlVariables,
    swrConfig
  )

  return {
    videoListLoading: isLoading,
    videoList: data && decorateVideoList(data, mutate),
    videoListError: error,
    videoListValidating: isValidating,
    mutateVideoList: mutate,
  }
}

const decorateVideoList = (
  videoResults: IVideoGQLResults,
  mutateVideoList: IReturnType['mutateVideoList']
): IVideoDecoratedGQLResults => {
  // Custom video.mutate that proxy change on mutateVideoList
  const mutate = buildGenericSwrMutate(mutateVideoList)

  const videoDecoratedCollection = videoResults.collection.map((video) =>
    decorateVideo(video, mutate)
  )

  return {
    ...videoResults,
    collection: videoDecoratedCollection,
  }
}
