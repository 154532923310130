import React from 'react'

import { Carousel } from '@/components/_shared/Carousel'
import { VideoListItemSkeleton } from '@/components/video/video/loaders/VideoListItemSkeleton'

interface IProps {
  nbVisibleItems?: number
}

export const VideoListCarouselLoading: React.FC<IProps> = ({ nbVisibleItems = 4 }) => (
  <Carousel nbVisibleItems={nbVisibleItems}>
    {[...Array(nbVisibleItems)].map((_, index) => (
      <VideoListItemSkeleton width={378} key={index} />
    ))}
  </Carousel>
)
