import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { Container } from '@/components/_layout/Container'
import { CtasWrapper } from '@/components/home/CtasWrapper'
import { DEVICE_WIDTH } from '@/utils/constants'
import { routes } from '@/utils/route'
import { getLinearShadow } from '@/utils/style'

export const HomeHeroSlice: React.FC = () => {
  return (
    <StyledContainer>
      <MediaContainer>
        <MediaWrapper>
          <VideoPlayer autoPlay loop muted playsInline>
            <source src="/videos/hero_v4_square_encode.mp4" type="video/mp4" />
            <source src="/videos/hero_v4_square_encode.webm" type="video/webm" />
          </VideoPlayer>
        </MediaWrapper>
      </MediaContainer>

      <ContentWrapper>
        <Title>Yoga, Pilates #ForAll</Title>

        <FormattedMessage
          tagName={StyledH1}
          defaultMessage="Cours de yoga, fitness, pilates, bien-être. Et tellement plus."
          description="HomeHeroSlice: h1"
        />
        <FormattedMessage
          tagName={Description}
          defaultMessage={`
            Avec OLY Be, pratiquez avec les meilleurs professeurs dans votre quartier ou en ligne. Nos cours s’adaptent à votre niveau et votre budget !
          `}
          description="HomeHeroSlice: description"
        />

        <CtasWrapper />

        <OnboardingWrapper>
          <FormattedMessage
            tagName={OnboardingText}
            defaultMessage="Quel cours est fait pour moi ?"
            description="Onboarding: sentence"
          />
          <CustomButtonLink href={routes.onboarding} outlined>
            <FormattedMessage
              defaultMessage="Guidez-moi"
              description="Onboarding: button"
            />
          </CustomButtonLink>
        </OnboardingWrapper>
      </ContentWrapper>
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  position: relative;
  text-align: center;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    text-align: left;
  }
`
const MediaContainer = styled.div`
  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    display: none;
  }

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 48%;
    z-index: -1;
  }
`
const MediaWrapper = styled.div`
  aspect-ratio: 1;
  margin: ${({ theme }) => theme.spacing.s} 0;
  position: relative;
  width: 100%;

  @supports not (aspect-ratio: 1) {
    padding-top: 100%;
  }

  ${getLinearShadow('100%', '100%', '-48px', '48px')}

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    &&::before {
      left: ${({ theme }) => theme.spacing.xl};
      top: ${({ theme }) => theme.spacing.m};
    }
  }
`
const VideoPlayer = styled.video`
  border-radius: 50%;
  max-width: 100%;
  object-fit: contain;
  overflow: hidden;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    margin: ${({ theme }) => theme.spacing.s};
  }
`
const ContentWrapper = styled.div`
  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    grid-column: 2/8;
    margin-top: ${({ theme }) => theme.spacing.m};
  }
`
const Title = styled.p`
  ${({ theme }) => theme.typo.h1Home}

  margin: 0 auto ${({ theme }) => theme.spacing.s};

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    margin: 0 0 ${({ theme }) => theme.spacing.ms};
  }
`
const StyledH1 = styled.h1`
  color: ${({ theme }) => theme.color.grey};
`
const Description = styled.p`
  color: ${({ theme }) => theme.color.grey};

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    width: 90%;
  }
`
const OnboardingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.m};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    flex-direction: row;

    & > *:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.m};
    }
  }
`
const OnboardingText = styled.p`
  ${({ theme }) => theme.typo.bold}

  margin-bottom: ${({ theme }) => theme.spacing.s};

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    margin-bottom: 0;
  }
`
