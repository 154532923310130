import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { theme } from 'theme'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { Container } from '@/components/_layout/Container'
import { ElleAdvantageSlice } from '@/components/_shared/_slice/ElleAdvantageSlice'
import { DEVICE_WIDTH } from '@/utils/constants'
import { routes } from '@/utils/route'

export const HomeOlybeElleStatementSlice: React.FC = () => (
  <>
    <ExperienceContainer>
      <FormattedMessage
        tagName={Statement}
        defaultMessage="OLY Be, une expérience recommandée par la rédaction de ELLE"
        description="HomeOlybeElleStatementSlice: Statement"
      />

      <Content>
        <FormattedMessage
          tagName={Title}
          defaultMessage="OLY Be et le magazine ELLE : deux partenaires engagés pour un impact positif dans le quotidien de tous."
          description="HomeOlybeElleStatementSlice: Title"
        />
        <FormattedMessage
          tagName={Description}
          defaultMessage={`
            <p>OLY Be a pour mission de rendre le sport et les pratiques bien être accessibles au plus grand nombre.</p>
            <p>Le magazine ELLE œuvre chaque jour pour les femmes et se tient à leurs côtés dans toutes les facettes de leur vie.</p>
            <p>
              Ensemble, nous unissons nos forces afin d’enrichir l’expérience #YogaForAll : faire du sport et du
              bien-être des pratiques simples, accessibles et qui n’excluent personne.
              #YogaForAll c’est un yoga engagé, solidaire, inclusif, passionné et surtout partagé.
            </p>
          `}
          description="HomeOlybeElleStatementSlice: Description"
          values={{
            p: (chunk) => <p>{chunk}</p>,
          }}
        />

        <ButtonLinkDiscover href={routes.about} outlined>
          <FormattedMessage
            defaultMessage="Découvrir"
            description="HomeOlybeElleStatementSlice: button"
          />
        </ButtonLinkDiscover>
      </Content>
    </ExperienceContainer>
  </>
)

const ExperienceContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.m};
  margin-bottom: ${({ theme }) => theme.spacing.xxxl};
  margin-top: ${({ theme }) => theme.spacing.xxxl};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    align-items: center;
    flex-direction: row;
    margin-bottom: 80px;
    margin-top: 168px;
  }
`
const Statement = styled.p`
  ${({ theme }) => theme.typo.h1};

  align-items: center;
  aspect-ratio: 1;
  background: radial-gradient(
    100% 100% at 80.92% 5%,
    ${({ theme }) => theme.color.terracotaLight} 0%,
    ${({ theme }) => theme.color.mandarine} 45%,
    ${({ theme }) => theme.color.mandarine} 60%,
    ${({ theme }) => theme.color.beige} 100%
  );
  border-radius: 50%;
  color: ${({ theme }) => theme.color.white};
  display: flex;

  @supports not (aspect-ratio: 1) {
    height: calc(100vw - 88px);
    max-height: 550px;
  }

  font-size: 8.6vw;
  justify-content: center;
  line-height: 10.2vw;
  margin: 0 ${({ theme }) => theme.spacing.ms};
  padding: ${({ theme }) => theme.spacing.m};
  text-align: center;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    flex: 1;
    font-size: 2.8vw;
    line-height: 3.9vw;
    margin: 0 ${({ theme }) => theme.spacing.ml};
  }

  @media screen and (min-width: ${DEVICE_WIDTH.DESKTOP}px) {
    font-family: 'Playfair Display Medium', serif;
    font-size: 72px;
    line-height: 66px;

    @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
      font-size: 64px;
      line-height: 64px;
    }
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    gap: ${({ theme }) => theme.spacing.m};
    margin-right: 8.33%;
    width: 45%;
  }
`
const Title = styled.h2`
  ${({ theme }) => theme.typo.h3}
  ${({ theme }) => theme.typo.pointBefore}
`
const Description = styled.div`
  padding-left: ${({ theme }) => theme.spacing.ms};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    padding-left: ${({ theme }) => theme.spacing.m};
  }
`
const ButtonLinkDiscover = styled(CustomButtonLink)`
  margin: 0 auto;
  width: fit-content;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin: 0;
    margin-left: ${({ theme }) => theme.spacing.ms};
  }
`
