import React from 'react'

import { Container } from '@/components/_layout/Container'
import { GenericSlice } from '@/components/_layout/GenericSlice'
import { CancelBookingModal } from '@/components/booking/CancelBookingModal'
import { LessonBookingContextProvider } from '@/contexts/lessonBooking'

interface IProps {
  title?: string
  sectionTitle?: string
}

export const LessonBookingContextSlice: React.FC<React.PropsWithChildren<IProps>> = ({
  title,
  sectionTitle,
  children,
}) => (
  <GenericSlice title={title} sectionTitle={sectionTitle}>
    <Container>
      <LessonBookingContextProvider>
        {children}
        <CancelBookingModal />
      </LessonBookingContextProvider>
    </Container>
  </GenericSlice>
)
