import { useRouter } from 'next/router'
import React from 'react'
import styled from 'styled-components'

import { ContainerError, ContainerLoading } from '@/components/_layout/ContainerMessage'
import { PromoBanner } from '@/components/_shared/PromoBanner'
import { usePromoBannerResult } from '@/hooks/use-promoBanner'
import { IAreaKey } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'

interface IProps {
  slot?: number
  areaKey?: IAreaKey
  className?: string
}

export const PromoBannerSlice: React.FC<IProps> = ({ slot = 1, areaKey, className }) => {
  const router = useRouter()
  const spot = router.pathname
  const { promoBannerLoading, promoBanner, promoBannerError } = usePromoBannerResult({
    spot,
    slot,
    areaKey,
  })

  if (promoBannerLoading) return <ContainerLoading />
  if (promoBannerError) return <ContainerError />
  if (!promoBanner) return null

  return (
    <Wrapper className={className}>
      <PromoBanner promoBanner={promoBanner} creativeSlot={`${spot + '#' + slot}`} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing.m} 0;
  position: relative;

  img {
    z-index: -1;
  }

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    padding: ${({ theme }) => theme.spacing.s} 0;
  }
`
