import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { ContainerError, ContainerLoading } from '@/components/_layout/ContainerMessage'
import { FormattedMessageCentered } from '@/components/_shared/FormattedMessage'
import { KeySwitcher } from '@/components/_shared/KeySwitcher'
import { useSWRLessonDualAreaResults } from '@/hooks/swr/useSwr-lessonDualAreaResults'
import { COOKIE_NAME, useCookie } from '@/hooks/use-cookie'
import { IGQLLessonVariables } from '@/services/api-graphql-lesson'
import { IAreaDualKey, IAreaKey } from '@/types/main'
import { AREA_DUAL_KEY, DEVICE_WIDTH } from '@/utils/constants'
import { buildLessonExploreRoute } from '@/utils/route'

import { LessonListItem } from './LessonListItem'

interface IProps {
  gqlVariables?: IGQLLessonVariables
  areaDualKey?: IAreaDualKey
  domId?: string
}

export const LessonListDualArea: React.FC<IProps> = ({
  gqlVariables,
  areaDualKey,
  domId,
}) => {
  const { lessonListDualAreaLoading, lessonListDualArea, lessonListDualAreaError } =
    useSWRLessonDualAreaResults(gqlVariables)
  const [activeAreaKey, setActiveAreaKey] = useState<IAreaDualKey>(
    areaDualKey ?? AREA_DUAL_KEY.ONSITE
  )
  const { cookieValue: cookieAreaKey } = useCookie<IAreaKey>(
    COOKIE_NAME.AREA_SELECTED,
    'paris'
  )
  const intl = useIntl()

  if (lessonListDualAreaLoading) return <ContainerLoading />
  if (lessonListDualAreaError) return <ContainerError />

  const activeCollection = lessonListDualArea[activeAreaKey].collection

  return (
    <div id={domId}>
      <StyledKeySwitcher
        activeKey={activeAreaKey}
        setActiveKey={setActiveAreaKey}
        items={[
          {
            key: AREA_DUAL_KEY.ONSITE,
            title: intl.formatMessage({
              defaultMessage: 'Cours en studio',
              description: 'LessonListDualArea: studio title',
            }),
          },
          {
            key: AREA_DUAL_KEY.ONLINE,
            title: intl.formatMessage({
              defaultMessage: 'Cours en live',
              description: 'LessonListDualArea: online title',
            }),
          },
        ]}
      />

      {activeCollection.length === 0 ? (
        <FormattedMessageCentered
          defaultMessage="Aucun cours programmé prochainement"
          description="LessonListDualArea: no results"
        />
      ) : (
        activeCollection.map((lesson) => (
          <LessonListItem lesson={lesson} key={lesson.uuid} />
        ))
      )}

      <StyledButtonLink
        outlined
        href={buildLessonExploreRoute(
          activeAreaKey === AREA_DUAL_KEY.ONSITE ? cookieAreaKey : AREA_DUAL_KEY.ONLINE
        )}
      >
        <FormattedMessage
          defaultMessage="Voir le planning des cours en {activeAreaKey, select, onsite {studio} other {live}}"
          description="LessonListDualArea: onsite/online link"
          values={{
            activeAreaKey,
          }}
        />
      </StyledButtonLink>
    </div>
  )
}

const StyledKeySwitcher = styled(KeySwitcher)`
  margin-bottom: ${({ theme }) => theme.spacing.m};
  margin-left: auto;
  margin-right: auto;
`
const StyledButtonLink = styled(CustomButtonLink)`
  margin-top: ${({ theme }) => theme.spacing.m};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
`
