import React from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

interface IProps {
  width: number
}

const VIDEO_RADIUS = 24
const LINE_HEIGHT = 22
const GAP = 8

export const VideoListItemSkeleton: React.FC<IProps> = ({ width }) => (
  <StyledContentLoader $width={width} uniqueKey="VideoListItemSkeleton">
    <rect
      x="0"
      y="0"
      rx={VIDEO_RADIUS}
      ry={VIDEO_RADIUS}
      width={width}
      height={(width * 67) / 100}
    />
    <rect
      x="0"
      y={(width * 67) / 100 + GAP}
      rx={LINE_HEIGHT / 2}
      ry={LINE_HEIGHT / 2}
      width={(width * 80) / 100}
      height={LINE_HEIGHT}
    />
    <rect
      x="0"
      y={(width * 67) / 100 + LINE_HEIGHT + GAP * 2}
      rx={LINE_HEIGHT / 2}
      ry={LINE_HEIGHT / 2}
      width={(width * 60) / 100}
      height={LINE_HEIGHT}
    />
  </StyledContentLoader>
)

const StyledContentLoader = styled(ContentLoader)<{ $width: number }>`
  height: ${({ $width }) => ($width * 67) / 100 + GAP * 2 + LINE_HEIGHT * 2}px;
  width: 100%;
`
