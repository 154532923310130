import { KeyedMutator, SWRConfiguration } from 'swr'

import { IDualAreaResults } from '@/services/api-graphql'
import {
  getLessonDualAreaResults,
  ILessonDecoratedGQLResults,
  ILessonDualAreaGQLResults,
  ILessonGQLResults,
  ILessonResultsVariables,
} from '@/services/api-graphql-lesson'
import { HTTPError } from '@/utils/api-helpers'
import { decorateLesson } from '@/utils/decorator'
import { buildGenericSwrMutateKey } from '@/utils/swr'

import { useSWRAuth } from './useSwr-auth'
import { useSWRCustom } from './useSwr-custom'

export const SWR_KEY__LESSON_DUALAREA_RESULTS = 'lessonDualArea_results'

interface IReturnType {
  lessonListDualAreaLoading: boolean
  lessonListDualArea: IDualAreaResults<ILessonDecoratedGQLResults>
  lessonListDualAreaError: HTTPError
  lessonListDualAreaValidating: boolean
  mutateLessonListDualArea: KeyedMutator<ILessonDualAreaGQLResults>
}

export const useSWRLessonDualAreaResults = (
  gqlVariables: ILessonResultsVariables = {},
  swrConfig: SWRConfiguration = { revalidateOnFocus: false }
): IReturnType => {
  const { payload } = useSWRAuth()
  const { data, error, mutate, isLoading, isValidating } =
    useSWRCustom<ILessonDualAreaGQLResults>(
      [SWR_KEY__LESSON_DUALAREA_RESULTS, payload?.uuid],
      getLessonDualAreaResults,
      gqlVariables,
      swrConfig
    )

  return {
    lessonListDualAreaLoading: isLoading,
    lessonListDualArea: data && decorateLessonListDualArea(data, mutate),
    lessonListDualAreaError: error,
    lessonListDualAreaValidating: isValidating,
    mutateLessonListDualArea: mutate,
  }
}

const decorateLessonListDualArea = (
  lessonDualAreaResults: ILessonDualAreaGQLResults,
  mutateLessonListDualArea: IReturnType['mutateLessonListDualArea']
): IReturnType['lessonListDualArea'] => {
  // Custom lesson.mutate that proxy change on mutateLessonListDualArea
  const mutate = buildGenericSwrMutateKey(mutateLessonListDualArea)

  const decorateLessons = (lessonResults: ILessonGQLResults) =>
    lessonResults.collection.map((lesson) => decorateLesson(lesson, mutate))

  return {
    onsite: {
      ...lessonDualAreaResults.onsite,
      collection: decorateLessons(lessonDualAreaResults.onsite),
    },
    online: {
      ...lessonDualAreaResults.online,
      collection: decorateLessons(lessonDualAreaResults.online),
    },
  }
}
