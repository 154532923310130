import React from 'react'

import { ContainerError } from '@/components/_layout/ContainerMessage'
import { Carousel } from '@/components/_shared/Carousel'
import { FormattedMessageCentered } from '@/components/_shared/FormattedMessage'
import { useSWRVideoResults } from '@/hooks/swr/useSwr-videoResults'
import { IVideoResultsVariables } from '@/services/api-graphql-video'

import { VideoListCarouselLoading } from './loaders/VideoListCarouselLoading'
import { VideoListItem } from './VideoListItem'

interface IProps {
  gqlVariables?: IVideoResultsVariables
  currentVideoUuid?: string
}

export const VideoListCarousel: React.FC<IProps> = ({
  gqlVariables,
  currentVideoUuid,
}) => {
  const { videoListLoading, videoList, videoListError } = useSWRVideoResults(gqlVariables)

  if (videoListLoading && !videoList) return <VideoListCarouselLoading />
  if (videoListError) return <ContainerError />

  if (!videoList.paginationInfo.totalCount) {
    return (
      <FormattedMessageCentered
        defaultMessage="Aucun cours en vidéo disponible"
        description="VideoListCarousel: no result"
      />
    )
  }

  return (
    <div>
      <Carousel>
        {videoList.collection
          .filter((video) => video.ulid !== currentVideoUuid)
          .map((video) => (
            <VideoListItem video={video} key={video.ulid} />
          ))}
      </Carousel>
    </div>
  )
}
