import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { GenericSlice } from '@/components/_layout/GenericSlice'
import { VideoListCarousel } from '@/components/video/video/VideoListCarousel'
import { DEVICE_WIDTH } from '@/utils/constants'
import { routes } from '@/utils/route'

export const VideoListSlice: React.FC = () => {
  const intl = useIntl()

  return (
    <GenericSlice
      title={intl.formatMessage({
        defaultMessage: 'Nos cours en vidéo',
        description: 'VideoListSlice: title',
      })}
      sectionTitle={intl.formatMessage({
        defaultMessage: 'Vidéos',
        description: 'VideoListSlice: section title',
      })}
    >
      <VideoListCarousel />

      <StyledButtonLink href={routes.videos} outlined>
        <FormattedMessage
          defaultMessage="Voir les cours en vidéo"
          description="VideoListSlice: button"
        />
      </StyledButtonLink>
    </GenericSlice>
  )
}

const StyledButtonLink = styled(CustomButtonLink)`
  margin-top: ${({ theme }) => theme.spacing.m};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin: auto;
    margin-top: ${({ theme }) => theme.spacing.l};
    width: fit-content;
  }
`
