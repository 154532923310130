import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { CustomButton } from '@/components/_custom/CustomButton'
import { DEVICE_WIDTH } from '@/utils/constants'

interface IProps {
  children: React.ReactNode[]
  nbVisibleItems?: number
  hideNavButtons?: boolean
  className?: string
}

export const Carousel: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  nbVisibleItems = 4,
  hideNavButtons = false,
  className,
}) => {
  const carouselRef = useRef<HTMLDivElement>()
  const [scrollOffset, setScrollOffset] = useState(0)
  const [maxOffset, setMaxOffset] = useState(0)
  const [itemWidth, setItemWidth] = useState(0)

  useEffect(() => {
    setMaxOffset(
      carouselRef.current.scrollWidth - carouselRef.current.getBoundingClientRect().width
    )
    setItemWidth(Math.round((carouselRef.current.scrollWidth - 16) / children.length))
  }, [carouselRef, children.length])

  const onClickPrev = () => {
    setScrollOffset((prevValue) => prevValue + itemWidth)
  }

  const onClickNext = () => {
    setScrollOffset((prevValue) => prevValue - itemWidth)
  }

  return (
    <Wrapper className={className}>
      <CarouselWrapper role="region" aria-label="Carousel">
        <CarouselItemList
          $nbItems={children.length}
          $scrollOffset={scrollOffset}
          ref={carouselRef}
        >
          {React.Children.map(children, (child, index) => (
            <CarouselItem key={index} $nbVisibleItems={nbVisibleItems}>
              {child}
            </CarouselItem>
          ))}
        </CarouselItemList>

        {!hideNavButtons && (
          <>
            <PrevButton
              onClick={onClickPrev}
              $visible={scrollOffset < 0}
              aria-label="Précédent"
              round
            >
              ←
            </PrevButton>
            <NextButton
              onClick={onClickNext}
              $visible={children.length >= nbVisibleItems && scrollOffset > -maxOffset}
              aria-label="Suivant"
              round
            >
              →
            </NextButton>
          </>
        )}
      </CarouselWrapper>
    </Wrapper>
  )
}

const getItemWidth = (nbVisibleItems: number) => css`
  min-width: calc(100% / (${nbVisibleItems - 1 / 2}));
`
const Wrapper = styled.div`
  overflow-x: hidden;
`
const CarouselWrapper = styled.div`
  position: relative;
`
const CarouselItemList = styled.div<{ $nbItems: number; $scrollOffset: number }>`
  -ms-overflow-style: none;
  align-items: stretch;
  display: flex;
  overflow-x: auto;
  padding-left: ${({ theme }) => theme.spacing.s};
  scrollbar-width: none;
  transform: ${({ $scrollOffset }) => `translateX(${$scrollOffset}px) `};
  transition: all 0.5s ease;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    overflow-x: inherit;

    ${({ $nbItems }) =>
      $nbItems <= 2 &&
      css`
        justify-content: center;
      `}
  }
`
const CarouselItem = styled.div<{ $nbVisibleItems?: number }>`
  min-width: 75%;
  padding-right: ${({ theme }) => theme.spacing.s};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    ${({ $nbVisibleItems }) => getItemWidth($nbVisibleItems - 1)};
  }

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    ${({ $nbVisibleItems }) => getItemWidth($nbVisibleItems)};
  }
`
const PrevNextButton = styled(CustomButton)<{ $visible: boolean }>`
  display: none;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    display: block;
    position: absolute;
    top: calc(50% - ${({ theme }) => theme.spacing.ms});
    transition: transform 0.1s ease-in-out;
    visibility: ${({ $visible }) => ($visible ? `all` : `hidden`)};
    width: ${({ theme }) => theme.spacing.l};
  }
`
const PrevButton = styled(PrevNextButton)<{ $visible: boolean }>`
  left: ${({ theme }) => theme.spacing.ms};
`
const NextButton = styled(PrevNextButton)<{ $visible: boolean }>`
  right: ${({ theme }) => theme.spacing.ms};
`
