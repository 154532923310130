import React from 'react'
import { theme } from 'theme'

import { FavoriteToggler } from '@/components/_shared/FavoriteToggler'
import { NextImage } from '@/components/_shared/NextImage'
import {
  StyledVideoBottomWrapper,
  StyledVideoImageCover,
  StyledVideoImageWrapper,
  StyledVideoInfosWrapper,
  StyledVideoListItemWrapper,
  StyledVideoProgress,
  StyledVideoThumbnailLink,
  StyledVideoTitle,
} from '@/components/video/style'
import { VideoBadge } from '@/components/video/video/misc/VideoBadge'
import { VideoCoverText } from '@/components/video/video/misc/VideoCoverText'
import { IVideo, IVideoDecorated } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'
import { buildVideoRoute } from '@/utils/route'

interface IProps {
  video: IVideoDecorated | IVideo
  videoGroupUlid?: string
  className?: string
}

export const VideoListItem: React.FC<IProps> = ({ video, videoGroupUlid, className }) => {
  const videoRoute = buildVideoRoute(video, videoGroupUlid)

  return (
    <StyledVideoListItemWrapper className={className}>
      <StyledVideoThumbnailLink href={videoRoute}>
        <StyledVideoImageWrapper>
          <NextImage
            src={video.thumbnailPath}
            alt={video.title}
            imgixPreconfig="videoCrop"
            sizes={`(max-width: ${DEVICE_WIDTH.MOBILE}px) 90vw, (max-width: ${DEVICE_WIDTH.TABLET}px) 40vw, 30vw`}
            fill
          />
        </StyledVideoImageWrapper>

        <StyledVideoImageCover>
          <VideoCoverText discipline={video.discipline} />
          {video.authUserSessionLatest?.sessionPercent && (
            <StyledVideoProgress value={video.authUserSessionLatest.sessionPercent} />
          )}
          {'mutate' in video && (
            <FavoriteToggler video={video} color={theme.color.white} />
          )}
          <VideoBadge flags={video.flags} />
        </StyledVideoImageCover>
      </StyledVideoThumbnailLink>

      <StyledVideoBottomWrapper>
        <StyledVideoTitle href={videoRoute}>{video.title}</StyledVideoTitle>
        <StyledVideoInfosWrapper>
          <p>{video.teachedBy?.name}</p>
          <p>{video.level?.title}</p>
          <p>{video.duration?.title}</p>
        </StyledVideoInfosWrapper>
      </StyledVideoBottomWrapper>
    </StyledVideoListItemWrapper>
  )
}
