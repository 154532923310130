import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

type FormattedMessageProps = React.ComponentProps<typeof FormattedMessage>

export const FormattedMessageCentered: React.FC<FormattedMessageProps> = (props) => (
  <Wrapper>
    <FormattedMessage tagName={Title} {...props} />
  </Wrapper>
)

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.m} 0;
`
const Title = styled.div`
  ${({ theme }) => theme.typo.bold}

  color: ${({ theme }) => theme.color.grey};
  margin: ${({ theme }) => `${theme.spacing.ms} 0 ${theme.spacing.m}`};
  text-align: center;
`
export const Line1 = styled.div`
  ${({ theme }) => theme.typo.p}

  font-weight: bold;
`
export const Line2 = styled.div`
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 16px;
`
