import React from 'react'
import styled from 'styled-components'

import { Container } from '@/components/_layout/Container'
import { DEVICE_WIDTH } from '@/utils/constants'

import { LessonAreaListSelector } from '../lesson/LessonAreaListSelector'

export const LessonAreaListSelectorSlice: React.FC = () => (
  <StyledContainer>
    <LessonAreaListSelector />
  </StyledContainer>
)

const StyledContainer = styled(Container)`
  border-top: 1px solid ${({ theme }) => theme.color.greyLighter};
  padding-top: ${({ theme }) => theme.spacing.ms};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    padding-top: ${({ theme }) => theme.spacing.m};
  }
`
