import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { DEVICE_WIDTH } from '@/utils/constants'
import { getLinearShadow } from '@/utils/style'

interface IProps {
  hideTitle?: boolean
  className?: string
  id?: string
}

export const CtaColumn: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  hideTitle = false,
  className,
  id,
}) => (
  <Wrapper id={id} className={className}>
    {!hideTitle && (
      <FormattedMessage
        tagName={Title}
        defaultMessage="Lancez-vous"
        description="CtaColumn: title"
      />
    )}
    {children}
  </Wrapper>
)

const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.spacing.ml};
  box-shadow: ${({ theme }) => theme.boxShadow.neutral};
  display: grid;
  gap: ${({ theme }) => theme.spacing.ms};
  grid-template-columns: 1fr;
  margin: ${({ theme }) => theme.spacing.l} 0;
  padding: ${({ theme }) => theme.spacing.m};

  ${({ theme }) => getLinearShadow('100%', '100%', theme.spacing.m, theme.spacing.m)}

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    border-radius: 80px;
    gap: ${({ theme }) => theme.spacing.m};
    margin-bottom: 0;
    margin-top: ${({ theme }) => theme.spacing.ms};
    max-width: 330px;
    padding: ${({ theme }) => theme.spacing.l};

    &&::before {
      left: ${({ theme }) => theme.spacing.xl};
      top: ${({ theme }) => theme.spacing.xl};
    }
  }
`
const Title = styled.p`
  ${({ theme }) => theme.typo.overline};
`
