import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  Description,
  DescriptionBold,
  EditoSlice,
  Title,
} from '@/components/_layout/EditoSlice'

export const HomeWellBeingSlice: React.FC = () => {
  const intl = useIntl()

  return (
    <EditoSlice
      imagePath="/pictures/home_2.jpg"
      imageAlt={intl.formatMessage({
        defaultMessage: 'Visages souriants de femmes partageant un cours de yoga',
        description: 'HomeWellBeingSlice: image alt',
      })}
    >
      <FormattedMessage
        tagName={Title}
        defaultMessage="Un yoga engagé, solidaire, inclusif, passionné et surtout partagé."
        description="HomeWellBeingSlice: title"
      />
      <div>
        <FormattedMessage
          tagName={DescriptionBold}
          defaultMessage={`
            Parce qu’on peut croire que le yoga est réservé aux plus aisés, qu’il faut être initié ou alors parfaitement
            souple, chez OLY Be, cela fait plus de 5 ans que nous travaillons à rendre cette pratique universelle.
          `}
          description="HomeWellBeingSlice: description bold"
        />
        <FormattedMessage
          defaultMessage={`
            <p>
              Qu’on vienne de Paris, Saint-Denis ou Limoges. Qu’on soit souple ou raide comme un piquet, qu’on ait 20 ans
              ou 3 fois 20, il doit s’ouvrir à tous, sans préjugés, pour permettre à chacun de s’épanouir et de se sentir bien.
            </p>
            <p>
              Avec OLY Be, vous n’avez pas à devenir le meilleur de vous-même. Juste à pratiquer pour le plaisir. Juste à vous
              sentir bien.
            </p>
          `}
          description="HomeWellBeingSlice: description"
          values={{ p: (chunks) => <Description>{chunks}</Description> }}
        />
      </div>
    </EditoSlice>
  )
}
