import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { theme } from 'theme'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { CtaColumn } from '@/components/_shared/CtaColumn'
import { DEVICE_WIDTH } from '@/utils/constants'
import { routes } from '@/utils/route'
import { getLinearShadow } from '@/utils/style'

export const CtasWrapper: React.FC = () => {
  return (
    <Wrapper>
      <StyledCtaColumn hideTitle>
        <FormattedMessage
          tagName={StudioTitle}
          defaultMessage="En studio"
          description="CtasWrapper: CTA studio title"
        />
        <CustomButtonLink href={routes.practice.studio}>
          <FormattedMessage
            defaultMessage="Découvrir"
            description="CtasWrapper: CTA studio button"
          />
        </CustomButtonLink>
      </StyledCtaColumn>

      <StyledCtaColumn hideTitle>
        <FormattedMessage
          tagName={OnlineTitle}
          defaultMessage="En ligne"
          description="CtasWrapper: CTA online title"
        />
        <CustomButtonLink
          color={theme.color.mandarine}
          href={routes.practice.online.index}
        >
          <FormattedMessage
            defaultMessage="Découvrir"
            description="CtasWrapper: CTA online button"
          />
        </CustomButtonLink>
      </StyledCtaColumn>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.ms};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    & > *:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.s};
    }
  }

  &&::before {
    left: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
    right: 0;
  }

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.ms};
  }

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    & > *:not(:last-child) {
      margin-bottom: 0;
    }

    &&::before {
      display: none;
    }
  }

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    margin: ${({ theme }) => theme.spacing.m} 0;
  }
`
const StyledCtaColumn = styled(CtaColumn)`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.s};
  justify-content: space-between;
  margin: 0;
  padding: ${({ theme }) => `${theme.spacing.ms} ${theme.spacing.s} ${theme.spacing.s}`};

  &&::before {
    display: none;
  }

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    gap: ${({ theme }) => theme.spacing.s};
    padding: ${({ theme }) => theme.spacing.m};
    text-align: center;
  }
`
const CtaTitle = styled.p`
  ${({ theme }) => theme.typo.h4}
  ${({ theme }) => theme.typo.bold}

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    ${({ theme }) => theme.typo.h3}
    ${({ theme }) => theme.typo.bold}
  }
`
const StudioTitle = styled(CtaTitle)`
  color: ${({ theme }) => theme.color.terracota};
`
const OnlineTitle = styled(CtaTitle)`
  color: ${({ theme }) => theme.color.mandarine};
`
const MobileText = styled.p`
  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: none;
  }
`

const DesktopText = styled.p`
  display: none;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: block;
  }
`
