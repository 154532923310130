import { LinkProps } from 'next/link'
import React, { Dispatch, SetStateAction } from 'react'
import styled, { css, DefaultTheme } from 'styled-components'

import { NextLink } from '@/components/_shared/NextLink'
import { DEVICE_WIDTH } from '@/utils/constants'
import { showNbItems } from '@/utils/style'

interface IProps {
  activeKey: string
  items: {
    key: string
    title: string
    counter?: number
  }[]
  className?: string
}

export const KeySwitcher: React.FC<
  IProps & { setActiveKey: Dispatch<SetStateAction<any>> }
> = ({ activeKey, setActiveKey, items, className }) => (
  <TabsWrapper className={className}>
    {items.map((item) => (
      <Tab
        onClick={() => setActiveKey(item.key)}
        $active={activeKey === item.key}
        key={item.key}
      >
        <Text $counter={item.counter} $active={activeKey === item.key}>
          {item.title}
        </Text>
      </Tab>
    ))}
  </TabsWrapper>
)

interface IPropsLink extends IProps {
  items: {
    key: string
    title: string
    counter?: number
    href: LinkProps['href']
  }[]
}

export const KeySwitcherLink: React.FC<IPropsLink> = ({
  activeKey,
  items,
  className,
}) => (
  <TabsWrapper className={className}>
    {items.map((item) => (
      <TabLink href={item.href} $active={activeKey === item.key} key={item.key}>
        <Text $counter={item.counter} $active={activeKey === item.key}>
          {item.title}
        </Text>
      </TabLink>
    ))}
  </TabsWrapper>
)

const TabsWrapper = styled.ul`
  align-items: center;
  background-color: ${({ theme }) => theme.color.beigeLight};
  border-radius: ${({ theme }) => theme.spacing.xxl};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.ms};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    width: fit-content;
  }
`
const getTabStyle = (theme: DefaultTheme, $active: boolean) => css`
  ${theme.typo.bold};

  align-items: center;
  background-color: ${$active && theme.color.terracota};
  border-radius: ${theme.spacing.xxl};
  color: ${$active && theme.color.white};
  cursor: ${!$active && 'pointer'};
  display: flex;
  flex: 1;
  height: ${theme.spacing.xl};
  justify-content: center;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    flex: inherit;
    padding: 0 ${theme.spacing.m};
  }
`
const Tab = styled.li<{ $active?: boolean }>`
  ${({ theme, $active }) => getTabStyle(theme, $active)};
`
const TabLink = styled(NextLink)<{ $active?: boolean }>`
  ${({ theme, $active }) => getTabStyle(theme, $active)};

  &:hover {
    color: ${({ theme, $active }) => ($active ? theme.color.white : theme.color.black)};
    text-decoration: none;
  }
`
const Text = styled.p<{ $counter: number; $active?: boolean }>`
  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    display: block;
    ${({ $counter }) => $counter && showNbItems($counter)}
    flex-wrap: nowrap;

    &::after {
      background-color: ${({ theme, $active }) =>
        $active ? theme.color.white : theme.color.terracota};
      color: ${({ theme, $active }) =>
        $active ? theme.color.terracota : theme.color.white};
    }
  }
`
